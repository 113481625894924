body {
  height: 100%;
}

html {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  height: 100%;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #CCBD74 !important;
  height: 40px !important;
  width: 40px !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #CCBD74 !important;
  height: 40px !important;
  width: 40px !important;
}

@media only screen and (max-width: 768px) {
  .MuiStepIcon-root.MuiStepIcon-active {
    color: #CCBD74 !important;
    height: 25px !important;
    width: 25px !important;
  }
  
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #CCBD74 !important;
    height: 25px !important;
    width: 25px !important;
  }
}

.MuiStepIcon-text {
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-style: italic;
}

.MuiTypography-body1 {
  font-family: "Helvetica Neue", sans-serif !important; 
}

.MuiPickersDay-daySelected {
  background-color: #CCBD74 !important;
}

.MuiPaper-elevation8 {
  margin-left: 30px;
}

.MuiTypography-alignCenter {
  color: #76B1B9;
}

.MuiPickersCalendarHeader-dayLabel {
  font-style: italic;
}

.MuiTypography-colorInherit {
  color: #76B1B9 !important;
  font-style: italic;
}

.MuiPickersToolbar-toolbar {
  background-color: #76B1B9 !important;
}

.MuiPickersToolbarText-toolbarBtnSelected {
  color: #CCBD74 !important;
}

.MuiPickersToolbarText-toolbarTxt {
  color: #CCBD74 !important;
}

.inputRounded .MuiOutlinedInput-root{
  border-radius: 25px;
}

.inputHalfRounded .MuiOutlinedInput-root{
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.noUi-connect {
  background: #76B1B9 !important;
}

.noUi-handle {
  background: #CCBD74 !important;
  box-shadow: none !important;
  visibility: hidden;
  border-radius: 100% !important;
  height: 23px !important;
  width: 23px !important;
  right: -15px !important;
}

.noUi-handle::before {
  height: 0px !important;
}

.noUi-handle::after {
  height: 0px !important;
}

.noUi-connects {
  border-radius: 25px !important;
}

.noUi-target {
  border-radius: 25px !important;
}